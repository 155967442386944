import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

const BlogDetails = () => {
    const { id } = useParams();
    console.log(id);
  return (
    <React.Fragment>
        <Header/>
        {/* <!-- Hero --> */}
        <section id="slider" className="hero p-0 odd featured">
            <div className="swiper-container no-slider slider-h-75">
                <div className="swiper-wrapper">

                    {/* <!-- Item 1 --> */}
                    <div className="swiper-slide slide-center">
                        
                        <img src="assets/images/bg-4.jpg" className="full-image" data-mask="70"/>
                        
                        <div className="slide-content row text-center">
                            <div className="col-12 mx-auto inner">
                                <h1 data-aos="zoom-out-up" data-aos-delay="400" className="title effect-static-text">Tips for having a good relationship at work.</h1>
                                <nav data-aos="zoom-out-up" data-aos-delay="800" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/#demos">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/#pages">Pages</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Single Post</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Content --> */}
        <section id="content" className="section-1 single featured">
            <div className="container">
                <div className="row">

                    {/* <!-- Main --> */}
                    <main className="col-12 col-lg-8 p-0">
                        <div className="row">
                            <div className="col-12 align-self-center">
                                <h2 className="featured mt-0 ml-0">Content</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc. Sed vitae tellus et arcu aliquet faucibus fermentum non lacus.</p>
                                <p>Praesent fringilla quis massa et placerat. Mauris eu dui eget urna pellentesque gravida vitae quis nibh. Ut at augue tortor. Pellentesque quis suscipit magna.</p>
                                <p>
                                    <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quam tortor, ultrices accumsan mauris eget, pulvinar tincidunt erat. Sed nisi nisi, rutrum sit amet elit.</blockquote>
                                </p>
                                <p>Sed mauris nulla, tempor eu est vel, dapibus hendrerit mauris curabitur dictum pharetra.</p>

                                {/* <!-- Image --> */}
                                <div className="gallery">
                                    <a href="assets/images/news-3.jpg">
                                        <img src="assets/images/news-3.jpg" className="w-100" />
                                    </a>
                                </div>

                                <p>Etiam mollis sem sed bibendum blandit. Aenean quis luctus ligula, vitae suscipit dui. Nunc diam orci, tincidunt eget consectetur sit amet, vulputate.</p>
                                <h4>Lorem ipsum dolor</h4>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                    <li>Consectetur adipiscing elit.</li>
                                    <li>Integer molestie lorem at massa.</li>
                                </ul>
                                <p>Sed mauris nulla, tempor eu est vel, dapibus hendrerit mauris curabitur dictum pharetra.</p>
                                <p>Etiam mollis sem sed bibendum blandit. Aenean quis luctus ligula, vitae suscipit dui. Nunc diam orci, tincidunt eget consectetur sit amet, vulputate.</p>

                                {/* <!-- Post Holder --> */}
                                <ul className="mb-5 post-holder">
                                    <li className="post-meta-item">
                                        <time className="date"><span className="posted-on">Posted on <a href="#" rel="bookmark"><time className="entry-date published updated" datetime="2018-11-01T06:18:46+00:00">November 12, 2019</time></a></span></time>
                                    </li>
                                </ul>

                                {/* <!-- Comments --> */}
                                <h3>Leave a Reply</h3>
                                <p>Sed mauris nulla, tempor eu est vel, dapibus hendrerit mauris.</p>
                                <form>
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-12 col-lg-6 input-group p-0 pr-lg-3">
                                                <input type="text" name="name" data-minlength="3" className="form-control" placeholder="Name" required />
                                            </div>
                                            <div className="col-12 col-lg-6 input-group p-0">
                                                <input type="email" name="email" data-minlength="3" className="form-control" placeholder="Email" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 input-group p-0">
                                                <textarea name="message" data-minlength="3" className="form-control" placeholder="Message" required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 input-group p-0">
                                            <a id="next-3" className="send btn primary-button">SEND<i className="icon-login left"></i></a>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>        
                    </main>

                    {/* <!-- Sidebar --> */}
                    <aside className="col-12 col-lg-4 pl-lg-5 p-0 float-right sidebar">                    
                        <div className="row">
                            <div className="col-12 align-self-center text-left">                                

                                {/* <!-- Widget [author] --> */}
                                <div className="item widget-author">  
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <a href="#" className="author">
                                                <img src="assets/images/team-1.jpg" alt="Lorem ipsum" />
                                                <h4 className="title">By John Doe</h4>
                                            </a>
                                            <p className="biography">I am a fan of motorsport and music and my hobby is to collect miniatures of racing cars.</p>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="d-lg-flex align-items-center"><i className="icon-clock mr-2"></i>3 Days Ago</span> <span className="badge tag m-0 active">Technology</span>
                                        </li>
                                    </ul>                                      
                                </div>

                                {/* <!-- Widget [categories] --> */}
                                <div className="item widget-categories">  
                                    <h4 className="title">Categories</h4>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <a href="#">Technology</a>
                                            <span className="badge circle">23</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <a href="#">Education</a>
                                            <span className="badge circle">41</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <a href="#">Industry</a>
                                            <span className="badge circle">26</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <a href="#">Development</a>
                                            <span className="badge circle">75</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <a href="#">Architecture</a>
                                            <span className="badge circle">39</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <a href="#">Management</a>
                                            <span className="badge circle">62</span>
                                        </li>
                                    </ul>                                
                                </div>

                                {/* <!-- Widget [tags] --> */}
                                <div className="item widget-tags">  
                                    <h4 className="title">Popular Tags</h4>
                                    <a href="#" className="badge tag">Mobile</a>
                                    <a href="#" className="badge tag">Development</a>
                                    <a href="#" className="badge tag">Technology</a>
                                    <a href="#" className="badge tag">App</a>
                                    <a href="#" className="badge tag">Education</a>
                                    <a href="#" className="badge tag">Business</a>
                                    <a href="#" className="badge tag">Health</a>
                                    <a href="#" className="badge tag">Industry</a>
                                </div>

                                {/* <!-- Widget [share-this] --> */}
                                <div className="item widget-share-this">
                                    <h4 className="title">Share This</h4>
                                    <ul className="navbar-nav social share-list">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link"><i className="icon-social-instagram ml-0"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link"><i className="icon-social-facebook"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link"><i className="icon-social-linkedin"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link"><i className="icon-social-twitter"></i></a>
                                        </li>
                                    </ul>
                                </div>

                                {/* <!-- Widget [gallery] --> */}
                                <div className="item widget-gallery">
                                    <h4 className="title">Image Gallery</h4>                                    
                                    <div className="gallery row justify-content-center">
                                        <a className="col-6 item" href="assets/images/portfolio-1.jpg">
                                            <img src="assets/images/gallery-1.jpg" className="w-100" />
                                        </a>
                                        <a className="col-6 item" href="assets/images/portfolio-2.jpg">
                                            <img src="assets/images/gallery-2.jpg" className="w-100" />
                                        </a>
                                        <a className="col-6 item" href="assets/images/portfolio-3.jpg">
                                            <img src="assets/images/gallery-3.jpg" className="w-100" />
                                        </a>
                                        <a className="col-6 item" href="assets/images/portfolio-4.jpg">
                                            <img src="assets/images/gallery-4.jpg" className="w-100" />
                                        </a>
                                        <a className="col-6 item" href="assets/images/portfolio-5.jpg">
                                            <img src="assets/images/gallery-5.jpg" className="w-100" />
                                        </a>
                                        <a className="col-6 item" href="assets/images/portfolio-6.jpg">
                                            <img src="assets/images/gallery-6.jpg" className="w-100" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </section>
        <Footer/>
    </React.Fragment>
  )
}

export default BlogDetails;