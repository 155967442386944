import React from 'react'

const Services = () => {
  return (
    <React.Fragment>
        {/* <!-- Services --> */}
        <section id="services" className="section-2 odd offers featured custom">
            <div className="container">
                <div className="row intro">
                    <div className="col-12 col-md-9 align-self-center text-center text-md-left">
                        <h2 className="super effect-static-text">Our Services</h2>
                        <p>Focused on results we seek to raise the level of our customers.</p>
                    </div>
                    <div className="col-12 col-md-3 align-self-end">
                        <a href="#" className="btn mx-auto mr-md-0 ml-md-auto primary-button"><i className="icon-grid"></i>VIEW ALL</a>
                    </div>
                </div>
                <div className="row justify-content-center text-center items">
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card featured">
                            <i className="icon icon-globe"></i>
                            <h4>Website Pro</h4>
                            <p>We build professional responsive websites optimized for the most popular search engines.</p>
                            <a href="page-single-service-1.html"><i className="btn-icon icon-arrow-right-circle"></i></a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card">
                            <i className="icon icon-basket"></i>
                            <h4>E-Commerce</h4>
                            <p>Increase your sales with an incredible online store, full of features and functionality.</p>
                            <a href="page-single-service-1.html"><i className="btn-icon icon-arrow-right-circle"></i></a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card">
                            <i className="icon icon-screen-smartphone"></i>
                            <h4>Mobile Apps</h4>
                            <p>Follow the global trend and create your revolutionary mobile app built with the best technologies.</p>
                            <a href="page-single-service-1.html"><i className="btn-icon icon-arrow-right-circle"></i></a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card">
                            <i className="icon icon-layers"></i>
                            <h4>Web Application</h4>
                            <p>We build applications for different purposes using technologies that allow you more security.</p>
                            <a href="page-single-service-1.html"><i className="btn-icon icon-arrow-right-circle"></i></a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card">
                            <i className="icon icon-chart"></i>
                            <h4>Digital Marketing</h4>
                            <p>We work to promote your brand in partnership with the best marketing platforms today.</p>
                            <a href="page-single-service-1.html"><i className="btn-icon icon-arrow-right-circle"></i></a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card featured">
                            <i className="icon icon-bulb"></i>
                            <h4>Brand Creation</h4>
                            <p>We create your brand thinking about your target audience using design techniques.</p>
                            <a href="page-single-service-1.html"><i className="btn-icon icon-arrow-right-circle"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}

export default Services;