import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (
    <React.Fragment>
        <Helmet>  
        <html lang="en" />  
        <title>UI Tags | Contact Us</title>  
        <meta name="description" content="Contact Page of UI Tags" />          
      </Helmet>
        <Header/>
        {/* <!-- Hero --> */}
        <section id="slider" className="hero p-0 odd featured">
            <div className="swiper-container no-slider slider-h-75">
                <div className="swiper-wrapper">

                    {/* <!-- Item 1 --> */}
                    <div className="swiper-slide slide-center">
                        
                        <img src="assets/images/bg-4.jpg" className="full-image" data-mask="70"/>
                        
                        <div className="slide-content row text-center">
                            <div className="col-12 mx-auto inner">
                                <h1 data-aos="zoom-out-up" data-aos-delay="400" className="title effect-static-text">Contact Us</h1>
                                <nav data-aos="zoom-out-up" data-aos-delay="800" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/#demos">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/#pages">Pages</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Contacts --> */}
        <section id="contacts" className="section-1 offers">
            <div className="container">
                <div className="row intro">
                    <div className="col-12 col-md-9 align-self-center text-center text-md-left">
                        <h2 className="featured">How Can We Help?</h2>
                        <p>Talk to one of our consultants today and learn how to start leveraging your business.</p>
                    </div>
                    <div className="col-12 col-md-3 align-self-end">
                        <a href="#contact" className="smooth-anchor btn mx-auto mr-md-0 ml-md-auto primary-button"><i className="icon-speech"></i>GET IN TOUCH</a>
                    </div>
                </div>
                <div className="row justify-content-center text-center items">
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card featured">
                            <i className="icon icon-phone"></i>
                            <h4>+1 123 98765 4321</h4>
                            <p className="mb-1">We answer by phone from Monday to Saturday from 10 am until 6 pm.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card">
                            <i className="icon icon-envelope"></i>
                            <h4>hello@business.com</h4>
                            <p className="mb-1">We will respond to your email within 8 hours on business days.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card featured">
                            <i className="icon icon-location-pin"></i>
                            <h4>Office Street, 123</h4>
                            <p className="mb-1">Come visit us from Monday to Friday from 11 am to 4 pm.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Custom --> */}
        <section id="custom" className="section-2 map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.123073808986!2d12.490042215441486!3d41.89021017922119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61b6532013ad%3A0x28f1c82e908503c4!2sColiseu!5e0!3m2!1spt-BR!2sbr!4v1594148229878!5m2!1spt-BR!2sbr" width="600" height="450" aria-hidden="false" tabindex="0"></iframe>
        </section>
        {/* <!-- Contact --> */}
        <section id="contact" className="section-3 form">
            <div className="container">
                <form action="php/form.php" id="leverage-form" className="multi-step-form">
                    <input type="hidden" name="section" value="leverage_form"/>

                    {/* <input type="hidden" name="reCAPTCHA">
                    <!-- Remove this field if you want to disable recaptcha --> */}

                    <div className="row">
                        <div className="col-12 col-md-6 align-self-start text-center text-md-left">

                            {/* <!-- Success Message --> */}
                            <div className="row success message">
                                <div className="col-12 p-0">
                                    <div className="wait">
                                        <div className="spinner-grow" role="status">
                                            <span className="sr-only">Loading</span>
                                        </div>
                                        <h3 className="sending">SENDING</h3>
                                    </div>
                                    <div className="done">
                                        <i className="icon bigger icon-check"></i>
                                        <h3>Your message was sent successful. Thanks.</h3>						
                                        <a href="" className="btn mx-auto primary-button">
                                            <i className="icon-refresh"></i>
                                            REFRESH
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Steps Message --> */}
                            <div className="row intro form-content">
                                <div className="col-12 p-0">

                                    {/* <!-- Step Title --> */}
                                    <div className="step-title">
                                        <h2 className="featured alt">Let's Talk?</h2>
                                        <p>Don't wait until tomorrow. Talk to one of our consultants today and learn how to start leveraging your business.</p>
                                    </div>

                                    {/* <!-- Step Title --> */}
                                    <div className="step-title">
                                        <h2 className="featured alt">Almost There</h2>
                                        <p>We need some more important information to better understand how we can help you in the best possible way.</p>
                                    </div>

                                    {/* <!-- Step Title --> */}
                                    <div className="step-title">
                                        <h2 className="featured alt">Are you Ready?</h2>
                                        <p>Tell us a little about the project you need to create. This is valuable so that we can direct you to the ideal team.</p>
                                    </div>

                                </div>
                            </div>

                            {/* <!-- Steps Group --> */}
                            <div className="row text-center form-content">
                                <div className="col-12 p-0">
                                    <ul className="progressbar">
                                        <li>Personal Details</li>
                                        <li>Company Budget</li>
                                        <li>Service Setup</li>
                                    </ul>

                                    {/* <!-- Group 1 --> */}
                                    <fieldset className="step-group">
                                        <div className="row">
                                            <div className="col-12 input-group p-0">
                                                <input type="email" name="email" data-minlength="3" className="form-control field-email" placeholder="Email"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 input-group p-0">
                                                <input type="text" name="name" data-minlength="3" className="form-control field-name" placeholder="Name"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 input-group p-0">
                                                <input type="text" name="phone" data-minlength="3" className="form-control field-phone" placeholder="Phone"/>
                                            </div>
                                        </div>
                                        <div className="col-12 input-group p-0">
                                            <a className="step-next btn primary-button">NEXT<i className="icon-arrow-right-circle left"></i></a>
                                        </div>
                                    </fieldset>

                                    {/* <!-- Group 2 --> */}
                                    <fieldset className="step-group">
                                        <div className="row">
                                            <div className="col-12 input-group p-0">
                                                <input type="text" name="company" data-minlength="3" className="form-control field-company" placeholder="Company"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 input-group p-0">
                                                <input type="text" name="manager" data-minlength="3" className="form-control field-manager" placeholder="Manager"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 input-group p-0">
                                                <i className="icon-arrow-down"></i>
                                                <select name="budget" data-minlength="1" className="form-control field-budget">
                                                    <option value="" selected disabled>What's your budget range?</option>
                                                    <option>Less than $2.000</option>
                                                    <option>$2.000 — $5.000</option>
                                                    <option>$5.000 — $10.000</option>
                                                    <option>$10,000+</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 input-group p-0 d-flex justify-content-between justify-content-md-start">
                                            <a className="step-prev btn primary-button mr-4"><i className="icon-arrow-left-circle"></i>PREV</a>
                                            <a className="step-next btn primary-button">NEXT<i className="icon-arrow-right-circle left"></i></a>
                                        </div>
                                    </fieldset>

                                    {/* <!-- Group 3 --> */}
                                    <fieldset className="step-group">
                                        <div className="row">
                                            <div className="col-12 input-group p-0">
                                                <textarea name="message" data-minlength="3" className="form-control field-message" placeholder="Message" required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 input-group p-0 d-flex justify-content-between justify-content-md-start">
                                            <a className="step-prev btn primary-button mr-4"><i className="icon-arrow-left-circle"></i>PREV</a>
                                            <a className="step-next btn primary-button">SEND<i className="icon-arrow-right-circle left"></i></a>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>

                        <div className="content-images col-12 col-md-6 pl-md-5 d-none d-md-block">

                            {/* <!-- Step 1 --> */}
							<div className="gallery">
                                <a className="step-image" data-poster="assets/images/about-8.jpg" href="https://www.youtube.com/watch?v=7e90gBu4pas">
                                    <i className="play-video icon-control-play"></i>
                                    <div className="mask-radius"></div>
                                    <img src="assets/images/about-8.jpg" className="fit-image" alt="Contact Us"/>
                                </a>
                            </div>

                            {/* <!-- Step 2 --> */}
							<div className="gallery">
                                <a className="step-image" href="assets/images/about-6.jpg">
                                    <img src="assets/images/about-6.jpg" className="fit-image" alt="Contact Us"/>
                                </a>
                            </div>

                            {/* <!-- Step 3 --> */}
							<div className="gallery">
                                <a className="step-image" href="assets/images/about-7.jpg">
                                    <img src="assets/images/about-7.jpg" className="fit-image" alt="Contact Us"/>
                                </a>
                            </div>

                            {/* <!-- Step 4 --> */}
							<div className="gallery">
                                <a className="step-image" href="assets/images/about-leverage.jpg">
                                    <img src="assets/images/about-leverage.jpg" className="fit-image" alt="Contact Us"/>
                                </a>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </section>
        <Footer/>
    </React.Fragment>
  )
}

export default ContactUs;