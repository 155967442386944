import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
import ContactUs from './pages/ContactUs';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';

const App = () => {
  return (
    <React.Fragment>
      {/* <Helmet>  
        <html lang="en" />  
        <title>UI Tags | Blog</title>  
        <meta name="description" content="Blog Page of UI Tags" />          
      </Helmet>  */}
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/about-us' element={<AboutUs/>} />
            <Route path='/services' element={<Services/>} />
            <Route path='/blog' element={<Blog/>} />
            <Route path='/blog-details' element={<BlogDetails/>} />
            <Route path='/contact-us' element={<ContactUs/>} />
        </Routes>
        
    </React.Fragment>
  );
}

export default App;
