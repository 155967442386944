import React from 'react'

const Skills = () => {
  return (
    <React.Fragment>
        {/* <!-- Skills --> */}
        <section id="skills" className="section-1 counter skills">
            <div className="container">
                <div className="row text-center intro">
                    <div className="col-12">
                        <h2 className="super effect-static-text">Main Skills</h2>
                        <p className="text-max-800">We see all types of projects as if they were ours. This brings us closer to our clients' projects bringing much more confidence and commitment.</p>
                    </div>
                </div>
                <div data-aos-id="counter" data-aos="fade-up" data-aos-delay="200" className="row justify-content-center text-center items">
                    <div className="col-12 col-md-6 col-lg-3 item">
                        <div data-percent="42" className="radial">
                            <span></span>
                        </div>
                        <h4>Marketing</h4>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 item">
                        <div data-percent="60" className="radial">
                            <span></span>
                        </div>
                        <h4>Branding</h4>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 item">
                        <div data-percent="84" className="radial">
                            <span></span>
                        </div>
                        <h4>Web Design</h4>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 item">
                        <div data-percent="100" className="radial">
                            <span></span>
                        </div>
                        <h4>WordPress</h4>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}

export default Skills