import React from 'react';

const Footer = () => {
  return (
    <React.Fragment>
         {/* <!-- Footer --> */}
        <footer className="odd">

            {/* <!-- Footer [links] --> */}
            <section id="footer" className="footer">
                <div className="container">
                    <div className="row items footer-widget">
                        <div className="col-12 col-lg-3 p-0">
                            <div className="row">
                                <div className="branding col-12 p-3 text-center text-lg-left item">
                                    <div className="brand">
                                        <a href="/" className="logo">
                                            Leverage.                                 
                                            {/* <!-- 
                                                Custom Logo
                                                <img src="assets/images/logo.svg" alt="Leverage">
                                            --> */}
                                        </a>
                                    </div>
                                    <p>Authentic and innovative.<br/>Built to the smallest detail<br/>with a focus on usability<br/>and performance.</p>
                                    <ul className="navbar-nav social share-list mt-0 ml-auto">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link"><i className="icon-social-instagram ml-0"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link"><i className="icon-social-facebook"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link"><i className="icon-social-linkedin"></i></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link"><i className="icon-social-twitter"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 p-0">                            
                            <div className="row">
                                <div className="col-12 col-lg-4 p-3 text-center text-lg-left item">
                                    <h4 className="title">Get in Touch</h4>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="icon-phone mr-2"></i>
                                                +1 123 98765 4321
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="icon-envelope mr-2"></i>
                                                hello@business.com
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="icon-location-pin mr-2"></i>
                                                Office Street, 123
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#contact" className="mt-4 mr-auto ml-auto ml-lg-0 btn dark-button smooth-anchor"><i className="icon-speech"></i>SEND A MESSAGE</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-lg-4 p-3 text-center text-lg-left item">
                                    <h4 className="title">Our Services</h4>
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Website Development</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Building Applications</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">SEO & Digital Marketing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Branding and Identity</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Digital Images & Videos</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-12 col-lg-4 p-3 text-center text-lg-left item">
                                    <h4 className="title">Popular Tags</h4>
                                    <a href="#" className="badge tag">Mobile</a>
                                    <a href="#" className="badge tag">Development</a>
                                    <a href="#" className="badge tag">Technology</a>
                                    <a href="#" className="badge tag">App</a>
                                    <a href="#" className="badge tag">Education</a>
                                    <a href="#" className="badge tag">Business</a>
                                    <a href="#" className="badge tag">Health</a>
                                    <a href="#" className="badge tag">Industry</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Copyright --> */}
            <section id="copyright" className="p-3 copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 p-3 text-center text-lg-left">
                            <p>Enjoy the low price. We are tracking any intention of piracy.</p>
                            {/* <!--
                                Suggestion: Replace the text above with a description of your website.
                             --> */}
                        </div>
                        <div className="col-12 col-md-6 p-3 text-center text-lg-right">
                            <p>© 2023 Leverage is Proudly Powered by <a href="#" target="_blank">Codings</a>.</p>
                        </div>
                    </div>
                </div>
            </section>

        </footer>
        {/* <!-- #region Global ============================ --> */}

        {/* <!-- Modal [search] --> */}
        <div id="search" className="p-0 modal fade" role="dialog" aria-labelledby="search" aria-hidden="true">
            <div className="modal-dialog modal-dialog-slideout" role="document">
                <div className="modal-content full">
                    <div className="modal-header" data-dismiss="modal">
                        Search <i className="icon-close"></i>
                    </div>
                    <div className="modal-body">
                        <form className="row">
                            <div className="col-12 p-0 align-self-center">
                                <div className="row">
                                    <div className="col-12 p-0 pb-3">
                                        <h2>What are you looking for?</h2>
                                        <p>Search for services and news about the best that happens in the world.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 p-0 input-group">
                                        <input type="text" className="form-control" placeholder="Enter Keywords"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 p-0 input-group align-self-center">
                                        <button className="btn primary-button"><i className="icon-magnifier"></i>SEARCH</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Modal [sign] --> */}
        <div id="sign" className="p-0 modal fade" role="dialog" aria-labelledby="sign" aria-hidden="true">
            <div className="modal-dialog modal-dialog-slideout" role="document">
                <div className="modal-content full">
                    <div className="modal-header" data-dismiss="modal">
                        Sign In Form <i className="icon-close"></i>
                    </div>
                    <div className="modal-body">
                        <form action="#" className="row">
                            <div className="col-12 p-0 align-self-center">
                                <div className="row">
                                    <div className="col-12 p-0 pb-3">
                                        <h2>Sign In</h2>
                                        <p>Don't have an account? <a href="#" className="primary-color" data-toggle="modal" data-target="#register">Register now</a>.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 p-0 input-group">
                                        <input type="email" className="form-control" placeholder="Email" required />
                                    </div>
                                    <div className="col-12 p-0 input-group">
                                        <input type="password" className="form-control" placeholder="Password" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 p-0 input-group align-self-center">
                                        <button className="btn primary-button"><i className="icon-login"></i>LOGIN</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Modal [register] --> */}
        <div id="register" className="p-0 modal fade" role="dialog" aria-labelledby="register" aria-hidden="true">
            <div className="modal-dialog modal-dialog-slideout" role="document">
                <div className="modal-content full">
                    <div className="modal-header" data-dismiss="modal">
                        Register Form <i className="icon-close"></i>
                    </div>
                    <div className="modal-body">
                        <form action="#" className="row">
                            <div className="col-12 p-0 align-self-center">
                                <div className="row">
                                    <div className="col-12 p-0 pb-3">
                                        <h2>Register</h2>
                                        <p>Have an account? <a href="#" className="primary-color" data-toggle="modal" data-target="#sign">Sign In</a>.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 p-0 input-group">
                                        <input type="text" className="form-control" placeholder="Name" required />
                                    </div>
                                    <div className="col-12 p-0 input-group">
                                        <input type="email" className="form-control" placeholder="Email" required />
                                    </div>
                                    <div className="col-12 p-0 input-group">
                                        <input type="password" className="form-control" placeholder="Password" required />
                                    </div>
                                    <div className="col-12 p-0 input-group">
                                        <input type="password" className="form-control" placeholder="Confirm Password" required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 p-0 input-group align-self-center">
                                        <button className="btn primary-button"><i className="icon-rocket"></i>REGISTER</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Modal [responsive menu] --> */}
        <div id="menu" className="p-0 modal fade" role="dialog" aria-labelledby="menu" aria-hidden="true">
            <div className="modal-dialog modal-dialog-slideout" role="document">
                <div className="modal-content full">
                    <div className="modal-header" data-dismiss="modal">
                        Menu <i className="icon-close"></i>
                    </div>
                    <div className="menu modal-body">
                        <div className="row w-100">
                            <div className="items p-0 col-12 text-center">
                                {/* <!-- Append [navbar] --> */}
                            </div>
                            <div className="contacts p-0 col-12 text-center">
                                {/* <!-- Append [navbar] --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Scroll [to top] --> */}
        <div id="scroll-to-top" className="scroll-to-top">
            <a href="#header" className="smooth-anchor">
                <i className="icon-arrow-up"></i>
            </a>
        </div>
    </React.Fragment>
  )
}

export default Footer;