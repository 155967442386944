import React from 'react'

const Team = () => {
  return (
    <React.Fragment>
        {/* <!-- Team --> */}
        <section id="team" className="section-4 carousel featured card-white">
            <div className="overflow-holder">
                <div className="container">
                    <div className="row text-center intro">
                        <div className="col-12">
                            <h2 className="super effect-static-text">Team of Experts</h2>
                            <p className="text-max-800">Our team will help you define a stand-out creative direction and will translate it into visual assets that will resonate with your audience.</p>
                        </div>
                    </div>
                    <div className="swiper-container mid-slider-simple items">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide slide-center text-center item">
                                <div className="row card">
                                    <div className="col-12">
                                        <img src="assets/images/team-1.jpg" alt="Adams Baker" className="person"/>
                                        <h4>Adams Baker</h4>
                                        <p>CEO / CO-FOUNDER</p>
                                        <ul className="navbar-nav social share-list ml-auto">
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-instagram"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-facebook"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-linkedin"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-twitter"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide slide-center text-center item">
                                <div className="row card">
                                    <div className="col-12">
                                        <img src="assets/images/team-2.jpg" alt="Mary Evans" className="person"/>
                                        <h4>Mary Evans</h4>
                                        <p>CONTROLLING / FOUNDER</p>
                                        <ul className="navbar-nav social share-list ml-auto">
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-instagram"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-facebook"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-linkedin"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-twitter"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide slide-center text-center item">
                                <div className="row card">
                                    <div className="col-12">
                                        <img src="assets/images/team-3.jpg" alt="Sarah Lopez" className="person"/>
                                        <h4>Sarah Lopez</h4>
                                        <p>HUMAN RESOURCES</p>
                                        <ul className="navbar-nav social share-list ml-auto">
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-instagram"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-facebook"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-linkedin"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-twitter"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide slide-center text-center item">
                                <div className="row card">
                                    <div className="col-12">
                                        <img src="assets/images/team-4.jpg" alt="Joseph Hills" className="person"/>
                                        <h4>Joseph Hills</h4>
                                        <p>CFO / CHIEF FINANCIAL</p>
                                        <ul className="navbar-nav social share-list ml-auto">
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-instagram"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-facebook"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-linkedin"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-twitter"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide slide-center text-center item">
                                <div className="row card">
                                    <div className="col-12">
                                        <img src="assets/images/team-5.jpg" alt="Karen Usman" className="person"/>
                                        <h4>Karen Usman</h4>
                                        <p>CTO / CHIEF MARKETING</p>
                                        <ul className="navbar-nav social share-list ml-auto">
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-instagram"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-facebook"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-linkedin"></i></a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#" className="nav-link"><i className="icon-social-twitter"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}

export default Team