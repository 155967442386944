import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import InnerHeader from '../components/InnerHeader';

const AboutUs = () => {
  return (
    <React.Fragment>
      <Header/>
      <InnerHeader/>
        {/* <!-- About [image] --> */}
        <section id="about" className="section-1 highlights image-right">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 align-self-center text-center text-md-left">
                        <div className="row intro">
                            <div className="col-12 p-0">
                                <h2 className="featured alt">About Us</h2>
                                <p>For over 8 years we have been developing digital solutions for companies looking to gain better visibility on the internet.</p>
                            </div>
                        </div>
                        <div className="row items">
                            <div className="col-12 p-0">
                                <div className="row item">
                                    <div className="col-12 col-md-2 align-self-center">
                                        <i className="icon icon-badge"></i>
                                    </div>
                                    <div className="col-12 col-md-9 align-self-center">
                                        <h4>Quality</h4>
                                        <p>Everything we do has the commitment of a well trained and motivated team.</p>
                                    </div>  
                                </div>
                                <div className="row item">
                                    <div className="col-12 col-md-2 align-self-center">
                                        <i className="icon icon-briefcase"></i>
                                    </div>
                                    <div className="col-12 col-md-9 align-self-center">
                                        <h4>Experience</h4>
                                        <p>Focused on results we seek to raise the level of our customers.</p>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gallery col-12 col-md-6">
                        <a href="assets/images/about-4.jpg">
                            <img src="assets/images/about-4.jpg" className="fit-image" alt="About Us" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
        
        {/* <!-- Features --> */}
        <section id="features" className="section-2 odd offers">
            <div className="container">
                <div className="row justify-content-center text-center items">
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card no-hover">
                            <i className="icon icon-globe"></i>
                            <h4>Website Pro</h4>
                            <p>We build professional responsive websites optimized for the most popular search engines.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card no-hover">
                            <i className="icon icon-basket"></i>
                            <h4>E-Commerce</h4>
                            <p>Increase your sales with an incredible online store, full of features and functionality.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        <div className="card no-hover">
                            <i className="icon icon-screen-smartphone"></i>
                            <h4>Mobile Apps</h4>
                            <p>Follow the global trend and create your revolutionary mobile app built with the best technologies.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- About [video] --> */}
        <section id="video" className="section-3 highlights image-center">
            <div className="container smaller">
                <div className="row text-center intro">
                    <div className="col-12">
                        <h2>Institutional</h2>
                        <p className="text-max-800">Get to know a little of our history and the road we traveled that took us to the level we are today.</p>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-12 gallery">
                        <a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="square-image d-flex justify-content-center align-items-center">
                            <i className="icon bigger icon-control-play"></i>
                            <img src="assets/images/news-4.jpg" className="fit-image" alt="Fit Image" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </React.Fragment>
  )
}

export default AboutUs;