import React from 'react';
import Footer from '../components/Footer';
import Form from '../components/Form';
import Get from '../components/Get';

import Header from '../components/Header';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Skills from '../components/Skills';
import Subscribe from '../components/Subscribe';
import Team from '../components/Team';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <React.Fragment>
      <Helmet>  
        <html lang="en" />  
        <title>UI Tags | Home</title>  
        <meta name="description" content="Blog Home Page of UI Tags" />          
      </Helmet>
        <Header/>
        <Hero/>
        <Skills/>
        <Services/>
        <Get/>
        <Team/>
        <Subscribe/>
        <Form/>
        <Footer/>        
    </React.Fragment>
  )
}

export default Home;