import React from 'react';

const Partners = () => {
  return (
    <React.Fragment>
        {/* <!-- Partners --> */}
        <section id="partner" className="section-2 odd logos">
            <div className="overflow-holder">
                <div className="container">
                    <div className="swiper-container min-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide slide-center item">
                                <img src="assets/images/logo-1.png" className="fit-image w-85" alt="Fit Image"/>
                            </div>
                            <div className="swiper-slide slide-center item">
                                <img src="assets/images/logo-2.png" className="fit-image w-85" alt="Fit Image"/>
                            </div>
                            <div className="swiper-slide slide-center item">
                                <img src="assets/images/logo-3.png" className="fit-image w-85" alt="Fit Image"/>
                            </div>
                            <div className="swiper-slide slide-center item">
                                <img src="assets/images/logo-4.png" className="fit-image w-85" alt="Fit Image"/>
                            </div>
                            <div className="swiper-slide slide-center item">
                                <img src="assets/images/logo-5.png" className="fit-image w-85" alt="Fit Image"/>
                            </div>
                            <div className="swiper-slide slide-center item">
                                <img src="assets/images/logo-6.png" className="fit-image w-85" alt="Fit Image"/>
                            </div>
                            <div className="swiper-slide slide-center item">
                                <img src="assets/images/logo-7.png" className="fit-image w-85" alt="Fit Image"/>
                            </div>
                            <div className="swiper-slide slide-center item">
                                <img src="assets/images/logo-8.png" className="fit-image w-85" alt="Fit Image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
  )
}

export default Partners;