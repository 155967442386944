import React from 'react';

const Get = () => {
  return (
    <React.Fragment>
        {/* <!-- Get --> */}
        <section id="get" className="section-3 hero odd p-0">
            <div className="swiper-container no-slider animation slider-h-75">
                <div className="swiper-wrapper">

                    {/* <!-- Item 1 --> */}
                    <div className="swiper-slide slide-center">

                        <video className="full-image" data-mask="90" playsinline autoplay muted loop>
                            <source src="assets/videos/project.mp4" type="video/mp4" />
                        </video> 
                        {/* <!-- 
                            Suggestion: Add a video to your assets folder and configure it here.

                            <video type="video/mp4" className="full-image" data-mask="70" src="assets/videos/yourvideo.mp4" autoplay muted loop></video> 
                        --> */}

                        <div className="slide-content row">
                            <div className="col-12 d-flex inner">
                                <div className="center align-self-center text-center">
                                    <h2 data-aos="zoom-out-up" data-aos-delay="400" className="title effect-static-text">ARE YOU READY?</h2>
                                    <p data-aos="zoom-out-up" data-aos-delay="800" className="description ml-auto mr-auto">We are driven by creativity. We create innovative things to help you achieve better results and consolidate yourself in the market.</p>
                                    <a href="#contact" data-aos="zoom-out-up" data-aos-delay="1200" className="smooth-anchor ml-auto mr-auto mt-5 btn primary-button"><i className="icon-rocket"></i>CONTACT US</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="swiper-pagination"></div>
            </div>
        </section>
    </React.Fragment>
  )
}

export default Get