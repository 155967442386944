import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import InnerHeader from '../components/InnerHeader';
import Subscribe from '../components/Subscribe';
import { Helmet } from 'react-helmet';

const Blog = () => {
   
  return (
    <React.Fragment>
        <Helmet>  
        <html lang="en" />  
        <title>UI Tags | Blog</title>  
        <meta name="description" content="Blog Page of UI Tags" />          
      </Helmet>
        <Header/>
        {/* <InnerHeader/> */}
        {/* <!-- Hero --> */}
        <section id="slider" className="hero p-0 odd featured">
            <div className="swiper-container no-slider slider-h-75">
                <div className="swiper-wrapper">

                    {/* <!-- Item 1 --> */}
                    <div className="swiper-slide slide-center">
                        
                        <img src="assets/images/bg-5.jpg" className="full-image" data-mask="70"/>
                        
                        <div className="slide-content row text-center">
                            <div className="col-12 mx-auto inner">
                                <h1 data-aos="zoom-out-up" data-aos-delay="400" className="title effect-static-text">Blog Posts</h1>
                                <nav data-aos="zoom-out-up" data-aos-delay="800" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/#demos">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/#pages">Pages</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Blog Posts</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        {/* <!-- Blog --> */}

       
        
        <section id="blog" className="section-1 showcase blog-grid masonry news">
            <div data-aos="zoom-in" data-aos-delay="200" data-aos-anchor="body" className="container">
                <div className="row content blog-grid masonry">
                    <main className="col-md-12 p-0">
                        <div className="bricklayer items columns-3">
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-1-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>Tips for having a good relationship at work.</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-2-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>Data scientists are a booming profession.</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div> 
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-3-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>Successful creations using virtual reality.</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div> 
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-4-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>Is the trend these days working from home?</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div> 
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-5-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>How digital transformation has changed the world.</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div> 
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-6-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>Why project management increases performance.</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-1-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>Tips for having a good relationship at work.</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-2-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>Data scientists are a booming profession.</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div> 
                            <div className="card p-0 text-center item">
                                <div className="image-over">
                                    <img src="assets/images/news-3-h.jpg" alt="Lorem ipsum"/>
                                </div>
                                <div className="card-caption col-12 p-0">
                                    <div className="card-body">
                                        <a href="page-single-post-1.html">
                                            <h4>Successful creations using virtual reality.</h4>
                                        </a>
                                    </div>
                                    <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-user"></i>John Doe</a>
                                        <a href="#" className="d-lg-flex align-items-center"><i className="icon-clock"></i>3 Days Ago</a>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </main>
                </div>

                {/* <!-- Pagination --> */}
                <div className="row">
                    <div className="col-12">
                        <nav>
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a className="page-link" href="#" tabindex="-1">
                                        <i className="icon-arrow-left"></i>
                                    </a>
                                </li>
                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                <li className="page-item active"><a className="page-link" href="#">3</a></li>
                                <li className="page-item"><a className="page-link" href="#">4</a></li>
                                <li className="page-item">
                                    <a className="page-link" href="#">
                                        <i className="icon-arrow-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <Subscribe/>
        <Footer/>
    </React.Fragment>
  )
}

export default Blog;